import "../scss/main.scss";
// import Modernizr from "modernizr";
import "foundation-sites";
import "lazysizes";
import "motion-ui/motion-ui";
import "datatables.net";
import $ from 'jquery';

// let mainSearchToggled = false;

$(document).foundation();
$(".media-modal__content-embed iframe").each(function () {
  let embed = $(this);
  let embedUrl = embed.attr("src");

  if (embedUrl.indexOf("youtube") > -1) {
    initYt(embed);
  } else {
    console.log(embedUrl + " not supported.");
  }
});

// Load YouTube API only if there are YouTube videos
if ($(".youtube-video").length) {
    insertTag();
}

function initYt(embed) {
    let src = embed.attr("src");
    if (!src.includes("enablejsapi")) {
        embed.attr("src", src + "&enablejsapi=1&rel=0");
    }
    embed.addClass("youtube-video");
}

function insertTag() {
  let tagToInsert = $('<script src="//www.youtube.com/player_api"></script>');
  let firstTag = $("script")[0];
  tagToInsert.insertBefore(firstTag);
}

   $("body").on("closed.zf.reveal", function (event, elem) {
    $('.youtube-video').each(function(){
      this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    });
    if ($(".media-modal__content").is(":hidden")) {
      if (typeof player !== 'undefined') {
        player.pauseVideo();
      }
    }
  });

  // When the Foundation reveal is opened
  $("body").on("open.zf.reveal", function (event, elem) {
    try {
      // Find the .youtube-video element within the opened reveal
      let openedReveal = $(event.target);
      let videoElement = openedReveal.find(".youtube-video");
  
      if (videoElement[0]) {
        let iframe = videoElement[0];
  
        // Attempt to play the video using postMessage
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      } else {
        throw new Error("YouTube video element not found in the opened reveal.");
      }
    } catch (error) {
      // console.error("Error playing YouTube video:", error);
    }
  });

  // navigation
  // prevent page from scrolling underneath mobile nav
  $("header").on("toggled.zf.responsiveToggle", function (event, elem) {
    $(".menu-icon").toggleClass("active");

    if ($(".is-drilldown").is(":visible")) {
      $("body").addClass("fixed");
    } else {
      $("body").removeClass("fixed");
    }
  });

  $(window).on("load resize", function () {
    var windowWidth = $(window).width();

    if (windowWidth > 768) {
        if ($("body").hasClass("fixed")) {
            $("body").removeClass("fixed");
        }
        $('.menu-icon').removeClass('active');
    }
  });

  // custom mobile nav back text
  // var customBackSet = false;

  // $(window).on('load resize', function() {
  //     var windowWidth = $(window).width();

  //     if (windowWidth < 768 && !customBackSet) {
  //         $('.custom-back').each(function() {
  //             var backTxt = $(this)
  //                 .parent()
  //                 .closest('.is-drilldown-submenu-parent')
  //                 .find('> a')
  //                 .text();
  //             $(this).text(backTxt);
  //         });
  //         customBackSet = true;
  //     } else if (windowWidth >= 768) {
  //         $('.menu-icon').removeClass('active');
  //         customBackSet = false;
  //     }
  // });

  $(".no-child").on({
    mouseover: function() {
        if ($(window).width() > 768) {
            $(this).addClass("is-active");
        }
    },
    mouseout: function() {
        if ($(window).width() > 768) {
            $(this).removeClass("is-active");
        }
    }
  });

  // adapted from https://github.com/kuofp/RWD-fb-plugin
  // resizes FB embed
  jQuery.fn.extend({
    rwd: function () {
      $(this).wrap('<div style="width: 100%; overflow: hidden;"></div>');

      var fb = this;
      var tm = $(fb).parent();

      $(window)
        .resize(function () {
          //default width height 500px
          var fb_w = parseInt($(fb).attr("data-width")) || 500;
          var fb_h = parseInt($(fb).attr("data-height")) || 800;
          var win_w = tm.width();
          var r_w = win_w / fb_w;

          $(fb)
            .css("transform", "scale(" + r_w + ")")
            .css("transform-origin", "0 0");
          $(tm).css("height", fb_h * r_w + "px");
        })
        .resize();
    },
  });

  $(function () {
    if ($(".fb-page")) {
      $(".fb-page").rwd();
    }
  });

  // // modal
  // $("body").on("open.zf.reveal", function (event, elem) {
  //   if ($(".search-modal__content").is(":visible")) {
  //     var searchOverlay = $(".reveal-overlay").last();
  //     $(searchOverlay).addClass("reveal-overlay--search");
  //   } else if ($(".media-modal__content").is(":visible")) {
  //     console.log('click');
  //     if (typeof player !== 'undefined') {
  //       playVideo();
  //     }
  //   }
  // });

  // $("body").on("closed.zf.reveal", function (event, elem) {
  //   if ($(".media-modal__content").is(":hidden")) {
  //     if (typeof player !== 'undefined') {
  //       pauseVideo();
  //     }
  //   }
  // });

  // // video logic
  // var player,
  //   embedUrl,
  //   isYt = true,
  //   embed = $(".media-modal__content-embed iframe");

  // $(embed).attr("class", "video");
  // console.log(embed);

  // if (embed.length) {
  //   embedUrl = $(embed).attr("src");
  //   if (embedUrl.indexOf("vimeo") > -1) {
  //     initVim();
  //   } else if (embedUrl.indexOf("youtube") > -1) {
  //     initYt();
  //   } else {
  //     console.log(embedUrl + " not supported.");
  //   }
  // }

  // function initVim() {
  //   player = new Vimeo.Player(embed);
  //   isYt = false;
  // }



  // function playVideo() {
  //   isYt ? player.playVideo() : player.play();
  // }

  // function pauseVideo() {
  //   isYt ? player.pauseVideo() : player.pause();
  // }

  // custom checkboxes
  $(function () {
    $(".form-content__label--checkbox").append("<span></span>");
  });

  // desktop search form
  $("#desktopSearchInput").on("input", function () {
    if ($(this).val().length > 0) {
      $(".nav-search__toggle").removeAttr("data-toggle");
      // mainSearchToggled = !mainSearchToggled;
      // console.log(mainSearchToggled);
      $(".nav-search__toggle").on("click", function () {
        $("#desktopSearchForm").submit();
      });
    } else {
      $(".nav-search__toggle").off("click");
      $(".nav-search__toggle").attr("data-toggle", "desktopSearchForm");
      $(".nav-search__toggle").foundation();
    }
  });

  //on click outside of main page search remove the input form that covers the navigation button 'get connected'
  // const navSearchButton = document.getElementsByClassName(
  //   "nav-search__toggle"
  // )[0];

  // const navSearchInput = document.getElementById('desktopSearchForm');

  // //I'm using "click" but it works with any event
  // document.addEventListener("click", function (event) {
  // //  const isClickInsideSearch = navSearchButton.contains(event.target);
  //   const isClickInsideForm = navSearchInput.contains(event.target);

  //   if (!isClickInsideForm) {
  //     $("#desktopSearchForm").removeClass("is-visible");
  //     $('.nav-search__toggle').attr('aria-expanded', false);
  //     $(".nav-search__toggle").attr("data-toggle", "desktopSearchForm");
  //     $(".nav-search__toggle").foundation();
  //   }

  // });
  // //end remove input form covering 'get connected'

  // ie detect
  // Modernizr.addTest("ie", function () {
  //   return (
  //     !!navigator.userAgent.match(/Trident/g) ||
  //     !!navigator.userAgent.match(/MSIE/g)
  //   );
  // });

  // mobile filters
  if ($(window).width() < 480) {
    $("#filter-toggle").click(function () {
      var filters = $(this).siblings(".news--filters__categories");
      filters.fadeToggle("fast");
      $("#filter-toggle").toggleClass("open");
    });
  }

  //if a rich tabular content table is part of the template
  if ($("#pages__rich-table").length) {
    $(document).ready(function () {
      $.noConflict();
      const table = $("#pages__rich-table").DataTable({
        order: [0, "desc"],
        pageLength: 25,
        lengthMenu: [10, 25, 50, 75, 100, 300, 500],
      });
    });
  }

  // Cache the mobile check result
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  function handleMobileContent() {
    if (isMobile) {
        const videoHero = $('.video-hero-container-image');
        videoHero.removeClass('hidden')
                 .removeAttr('style')
                 .addClass('is-active');
        $('.video-hero-container-video').remove();
    }
  }

  // Only run on homepage
  if (window.location.pathname === '/') {
    handleMobileContent();
  }

  // Call the function on page load
  // Get the current URL
  const currentURL = window.location.href;

  // Check if the URL ends with "/" (indicating the homepage)
  if (currentURL.endsWith("/")) {
    handleMobileContent();
  }
  

    // Function to set active slide
    function setActiveSlide() {
      // Check if any slide has the 'is-active' class
      var isActiveSlide = document.querySelector('.orbit-bullets button.is-active');
      
      // If no slide is active, set the first slide as active
      if (!isActiveSlide) {
        console.log('no slide active');
          var slides = document.querySelectorAll('.orbit-bullets button');
          if (slides.length > 0) {
              // Add 'is-active' class to the first slide
              slides[2].classList.add('is-active');
          }
      }
  }

  // // Set active slide when DOM content is loaded
  // setActiveSlide();

  // // Add event listener for orbit next button
  // document.querySelector('.orbit-next').addEventListener('click', function() {
  //     // Set active slide after a short delay to allow orbit to update
  //     setTimeout(setActiveSlide, 100);
  // });

  // // Add event listener for orbit previous button
  // document.querySelector('.orbit-previous').addEventListener('click', function() {
  //     // Set active slide after a short delay to allow orbit to update
  //     setTimeout(setActiveSlide, 100);
  // });